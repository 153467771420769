import React from "react";
import { flexRender, getCoreRowModel, useReactTable, getSortedRowModel, getPaginationRowModel } from '@tanstack/react-table'
import { Link } from "react-router-dom";
import coinlogo from "./logos/coinlogo.svg";
import { ProgressBar, Table, Button, Form } from 'react-bootstrap';
import MainAd from "./MainAd";
import { useTheme } from './Theme/ThemeContext';

const SymbolsTable = (props) => {
  const { columnVisibility = {
    Rank: false,
    SymbolName: true,
    Symbol: false,
    Last: true,
    QuoteVolume: true,
    VolumePercent: true,
  } } = props;

  const { theme } = useTheme();
  const coins = props.data;
  const fullvol = props.full;
  const sponsor = props.sponsor;

  const sponsoredSymbols = ["BTCBRL", "SOLBRL", "ETHBRL", "USDTBRL", "PEPEBRL", "USDCBRL", "XRPBRL", "TRUMPOFFICIALBRL"]

  const max = coins.sort(
    function (a, b) {
      return parseFloat(b['QuoteVolume']) - parseFloat(a['QuoteVolume']);
    }
  )[0]['QuoteVolume'] / fullvol * 100

  // If a sponsored symbol is found, move it to the beginning of the table data
  const sponsoredCoin = coins.find(coin => coin.Symbol === sponsor);
  if (sponsoredCoin) {
    const index = coins.findIndex(coin => coin.Symbol === sponsor);
    coins.splice(index, 1);
    coins.unshift(sponsoredCoin);
  }

  const columns = React.useMemo(() => [
    {
      header: '#',
      accessorKey: 'Rank',
      enableSorting: true,
    },
    {
      header: () => (
        <span data-accessor="sticky">Nome</span>
      ),
      accessorKey: 'SymbolName',
      enableSorting: true,
      cell: info => {
        const symbol = info.row.original.Symbol;
        const symbolName = info.row.original.SymbolName;
        const logoId = symbol.slice(0, -3).toLowerCase() + "-logo";

        return (
          <>
            <Link to={`/${symbol}`}>
              <svg className="tablelogo" role="img" aria-labelledby="title">
                <title id="title">{symbolName}</title>
                <use href={`${coinlogo}#${logoId}`}></use>
              </svg>
              <br className="d-md-none" />
              <span className=" text-body">{symbolName}</span><span className="text-body-secondary text-decoration-none"> {symbol.slice(0, -3)}</span>
            </Link>
            <span className="ms-3">{sponsoredSymbols.includes(symbol) && props.ad === true ? <MainAd /> : <></>}</span>
          </>
        );
      },
    },
    {
      header: 'Par',
      accessorKey: 'Symbol',
      enableSorting: false,
    },
    {
      header: 'Preço',
      accessorKey: 'Last',
      cell: info => (
        <>
          {(info.getValue() > 0.1) ? info.getValue().toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : ("R$ " + info.getValue().toLocaleString('pt-BR', { style: 'decimal', maximumSignificantDigits: 8 }))}
        </>
      ),
    },
    {
      header: 'Volume(24h)',
      accessorKey: 'QuoteVolume',
      cell: info => (
        <>
          <div>{info.getValue().toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</div>
          <span className="text-muted small">{parseFloat(info.row.original.Volume).toLocaleString('pt-BR', { style: 'decimal', maximumFractionDigits: 2 }) + " " + info.row.original.Symbol.slice(0, -3)}</span>
        </>
      ),
    },
    {
      header: (props.marketshare) ? 'Marketshare' : 'Volume(%)',
      accessorFn: row => (row.QuoteVolume / fullvol) * 100,
      accessorKey: 'VolumePercent',
      cell: info => (
        <ProgressBar max={max} now={info.row.original.QuoteVolume / fullvol * 100} style={{ minWidth: "2em" }} variant={`${theme === 'dark' ? 'primary' : 'info'}`} label={`${(info.row.original.QuoteVolume / fullvol).toLocaleString('pt-BR', { style: 'percent', maximumFractionDigits: 2, minimumFractionDigits: 1 })}`} />
      ),
    },
  ], [fullvol, max, props.marketshare, theme, props.ad]);

  const table = useReactTable({
    data: coins,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    columnResizeMode: 'onChange',
    initialState: {
      columnVisibility,
      pagination: {
        pageIndex: 0,
        pageSize: 20,
      },
    }
  });

  return (
    <div className="react-bootstrap-table table-responsive">
      <Table hover responsive size="sm">
        <thead>
          {table.getHeaderGroups().map(headerGroup => (
            <tr key={headerGroup.id} className="header-class text-nowrap border-bottom border-light-subtle">
              {headerGroup.headers.map((header, index) => {
                return (
                  <th key={header.id} colSpan={header.colSpan} className={`bg-body-tertiary text-body-secondary py-4 ${index === 0 ? 'ps-2' : ''}`}>
                    {header.isPlaceholder ? null : (
                      <div
                        onClick={header.column.getToggleSortingHandler()}
                        title={
                          header.column.getCanSort()
                            ? header.column.getNextSortingOrder() === 'asc'
                              ? 'Sort ascending'
                              : header.column.getNextSortingOrder() === 'desc'
                                ? 'Sort descending'
                                : 'Clear sort'
                            : undefined
                        }
                      >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                        {{
                          asc: <svg role="button" width="15px" height="15px" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M3.9 15L12 6.9l8.1 8.1z" /><path fill="none" d="M0 0h24v24H0z" /></svg>
                          ,
                          desc: <svg role="button" fill="currentColor" width="15px" height="15px" viewBox="0 0 10 10" xmlns="http://www.w3.org/2000/svg"><g><polygon points="8 3 5 7 2 3 8 3" /></g></svg>
                          ,
                        }[header.column.getIsSorted()] ??
                          <svg role="button" width="15px" height="15px" viewBox="0 0 24 24" fill="#ccc" xmlns="http://www.w3.org/2000/svg"><path d="M18.1 15L12 21.1 5.9 15zm0-6L12 2.9 5.9 9z" /><path fill="none" d="M0 0h24v24H0z" /></svg>
                        }
                      </div>
                    )}
                  </th>
                )
              })}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map(row => (
            <tr key={row.id} className={`border-bottom border-light-subtle ${row.original.Symbol === sponsor ? 'bg-sponsored' : ''}`}>
              {row.getVisibleCells().map(cell => (
                <td key={cell.id} className={`${(props.marketshare) ? 'py-2' : ''}`}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </Table>

      {/* Pagination */}
      {table.getRowCount() >= 20 && (
        <div className="d-flex flex-column flex-md-row  mt-3 gap-1 py-1 justify-content-between" id="table-pagination">
          <div className="flex-fill flex-wrap d-none d-md-flex  d-md-inline text-muted small fw-semibold">
            Mostrando {table.getState().pagination.pageIndex * table.getState().pagination.pageSize + 1}-
            {(table.getState().pagination.pageIndex + 1) * table.getState().pagination.pageSize > table.getRowCount() ? table.getRowCount() : (table.getState().pagination.pageIndex + 1) * table.getState().pagination.pageSize} de {table.getRowCount().toLocaleString()} linhas
          </div>

          <div className=" flex-fill">
            <Button onClick={() => table.previousPage()} disabled={!table.getCanPreviousPage()} variant="link" className="bg-transparent btn-pagination text-body border-0 fw-semibold px-3 rounded-3 mb-1">
              <svg viewBox="0 0 24 24" fill="currentColor" width="14">
                <path stroke="text-body" strokeWidth="2" fillRule="evenodd" d="M7.72 12.53a.75.75 0 0 1 0-1.06l7.5-7.5a.75.75 0 1 1 1.06 1.06L9.31 12l6.97 6.97a.75.75 0 1 1-1.06 1.06l-7.5-7.5Z" clipRule="evenodd" />
              </svg>
            </Button>

            {/* Page Numbers */}
            {Array.from({ length: table.getPageCount() }, (_, index) => {
              const pageIndex = index + 1;
              const currentPage = table.getState().pagination.pageIndex + 1;

              if (table.getPageCount() <= 5) {
                return (
                  <Button
                    key={index}
                    onClick={() => table.setPageIndex(index)}
                    active={index === table.getState().pagination.pageIndex}
                    variant="primary"
                    size="sm"
                    className="shadow-none btn-pagination border-0 fw-semibold px-3 py-2 rounded-3 text-body"
                  >
                    {pageIndex}
                  </Button>
                );
              } else {
                if (pageIndex <= 5 || pageIndex === table.getPageCount() || Math.abs(pageIndex - currentPage) <= 2) {
                  return (
                    <Button
                      key={index}
                      onClick={() => table.setPageIndex(index)}
                      active={index === table.getState().pagination.pageIndex}
                      variant="primary"
                      size="sm"
                      className="shadow-none btn-pagination border-0 fw-semibold px-3 py-2 rounded-3 text-body"
                    >
                      {pageIndex}
                    </Button>
                  );
                } else if (pageIndex === 6 && currentPage < table.getPageCount() - 1) {
                  return <span key="ellipsis-start">...</span>;
                } else if (pageIndex === table.getPageCount() - 1 && currentPage > 6) {
                  return <span key="ellipsis-end">...</span>;
                }
                return null;
              }
            })}

            <Button onClick={() => table.nextPage()} disabled={!table.getCanNextPage()} variant="link" className="btn-pagination bg-transparent text-body border-0 fw-semibold rounded-3 mb-1">
              <svg viewBox="0 0 24 24" fill="currentColor" width="14">
                <path stroke="text-body" strokeWidth="2" fillRule="evenodd" d="M16.28 11.47a.75.75 0 0 1 0 1.06l-7.5 7.5a.75.75 0 0 1-1.06-1.06L14.69 12 7.72 5.03a.75.75 0 0 1 1.06-1.06l7.5 7.5Z" clipRule="evenodd" />
              </svg>
            </Button>
          </div>

          <div className="ms-auto align-items-center d-none d-md-flex ">
            <span className="me-2 text-muted fw-semibold small">Linhas:</span>
            <Form.Select
              value={table.getState().pagination.pageSize}
              onChange={e => {
                table.setPageSize(Number(e.target.value));
              }}
              className="form-select-sm"
            >
              {[10, 20, 30, 40, 50].map(pageSize => (
                <option key={pageSize} value={pageSize}>
                  {pageSize}
                </option>
              ))}
            </Form.Select>
          </div>
        </div>
      )}
    </div>
  );
};

export default SymbolsTable;

import React from "react";
import { Container } from 'react-bootstrap';
import ExchangeTable from './components/ExchangeTable'
import { Helmet } from "react-helmet-async";
import { VariationIndicator, formatBigNumbers } from "./utils/priceFormats";
import NewsFeed from './components/NewsFeed.js'
import MainPageAccordion from './components/MainPageAccordion.js'

const Exchanges = (props) => {
  const exchanges = props.exchanges

  const BreadcrumbList = {
    "@context": "http://schema.org",
    "@type": "BreadcrumbList",
    "itemListElement": [
      {
        "@type": "ListItem",
        "position": 1,
        "name": "Criptomoedas",
        "item": "https://mercadocripto.livecoins.com.br/"
      },
      {
        "@type": "ListItem",
        "position": 2,
        "name": "Corretoras",
        "item": "https://mercadocripto.livecoins.com.br/corretoras",
      }
    ]
  };

  const FAQPage = {
    "@context": "http://schema.org",
    "@type": "FAQPage",
    "mainEntity": [
      {
        "@type": "Question",
        "name": "Qual a maior corretora de criptomoeda do Brasil em volume?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "A maior corretora de criptomoedas que opera no Brasil é a " + exchanges[0].Exchange + ". Nessa corretora foram negociados um total de " + exchanges.reduce((a, e) => a + e.QuoteVolume, 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) + " nas últimas 24 horas, somando apenas todos os pares com BRL."
        }
      },
      {
        "@type": "Question",
        "name": "Quantas corretoras de criptomoedas aceitam PIX ou TED de real brasileiro (BRL)?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Existem hoje um total de " + exchanges.lenght + " corretoras de criptomoedas operando no Brasil, e todas essas aceitam PIX ou TED para depósitos e retiradas."
        }
      },
      {
        "@type": "Question",
        "name": "Qual o volume diário de criptomoedas negociadas no Brasil?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "O volume total de criptomoedas negociados nas últimas 24 horas apenas no Brasil é de " + exchanges.reduce((a, e) => a + e.QuoteVolume, 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) + ", somando todos os pares com BRL."
        }
      },
    ]
  }

  const volumeToday = exchanges?.reduce((a, e) => a + e.QuoteVolume, 0);
  const volumeYesterday = exchanges?.reduce((a, e) => a + e.YesterdayVolume, 0);

  return (
    <>
      <Helmet>
        <title>Ranking de corretoras brasileiras de criptomoedas por volume · MercadoCripto</title>
        <meta name="description" content="Ranking de corretoras brasileiras de criptomoedas, com detalhes do volume diário, o preço de cada par negociado. Acesso a gráficos, diferenças de preço (ágio/spread), e dados históricos de todas as corretoras do Brasil." />
        <script type="application/ld+json">{JSON.stringify(BreadcrumbList)}</script>
        <script type="application/ld+json">{JSON.stringify(FAQPage)}</script>
      </Helmet>
      <Container>
        <h1 className="h1 fw-semibold mt-4">Ranking de Corretoras Nacionais</h1>
        <p className="my-4 fw-semibold text-muted">
          As principais corretoras brasileiras de criptomoedas classificadas por volume de negociação.<br />No último período de 24 horas, o volume negociado nas corretoras brasileiras de criptomoedas alcançou um total de <span className="fw-bold text-body">R$ {formatBigNumbers(volumeToday)}</span> , uma variação de <VariationIndicator valueToday={volumeToday} valueYesterday={volumeYesterday} /> em relação às 24h anteriores.</p>
        <MainPageAccordion exchanges={exchanges}/>
      </Container>

      <Container className="p-2 mt-5">
        <ExchangeTable data={exchanges} sponsor="OKX" visibleColumns={['Rank', 'Name', 'QuoteVolume', '24h', 'reserves', 'Confiabilidade', 'Domicilio',  'Nsymbols', 'VolumePercent']}  id="table" />
        <div className="d-none d-md-block">
          <NewsFeed slug={Math.random() < 0.5 ? 'criptomoedas' : 'noticias'} />
        </div>
      </Container>
    </>
  );
}

export default Exchanges;
import React from "react";
import { Card, Row, Placeholder, Col } from "react-bootstrap";
import { useRSSFeed } from "../utils/api";
import he from "he";

const epochs = [
    ['ano', 31536000],
    ['mês', 2592000],
    ['dia', 86400],
    ['hora', 3600],
    ['minuto', 60],
    ['segundo', 1]
];

const getDuration = (seconds) => {
    for (let [unit, value] of epochs) {
        const interval = Math.floor(seconds / value);
        if (interval >= 1) {
            return { interval, unit };
        }
    }
    return { interval: 0, unit: 'segundo' }; // For intervals less than 1 second
};

const timeAgo = (date) => {
    const timeAgoInSeconds = Math.floor((new Date() - new Date(date)) / 1000);
    const { interval, unit } = getDuration(timeAgoInSeconds);
    const suffix = (interval !== 1 && unit === 'mês') ? 'es' : (interval !== 1 && unit !== 'mês') ? 's' : '';
    
    return `${interval} ${unit}${suffix} atrás`;
};

const Newsfeed = ({ slug }) => {
    const { data, isLoading, error } = useRSSFeed(slug);
    let feed = [];

    if (data) {
        feed = data.rss.channel.item.map((item) => {
            const parserDom = new DOMParser();
            const doc = parserDom.parseFromString(item.description, "text/html");
            const text = he.decode(doc.body.textContent); // Decode HTML entities
            const img = doc.body.querySelector("img")?.getAttribute("src") || "";

            return {
                title: he.decode(item.title), // Decode HTML entities
                link: item.link,
                description: text,
                image: img,
                pubDate: item.pubDate,
            };
        });
    }

    if (error) {
        return <>{console.error(error.message)}</>;
    }

    return (
        <>
            <h3 className="my-5 fw-semibold h3">Notícias</h3>
            <Row xs={1} md={3} className="g-5 mb-5">
                {isLoading ? (
                    [0, 1, 2].map((_, idx) => (
                        <Col key={idx}>
                            <Card className="h-100 gy-2 bg-transparent border-0">
                                <div className="card-img-top rounded-4 bg-primary opacity-75" />
                                <Card.Body className="px-0 mx-0 pt-3 pb-2">
                                    <Placeholder as={Card.Title} animation="wave">
                                        <Placeholder xs={6} />
                                    </Placeholder>
                                    <Placeholder as={Card.Text} animation="wave">
                                        <Placeholder xs={7} /> <Placeholder xs={4} /> <Placeholder xs={4} />{" "}
                                        <Placeholder xs={6} /> <Placeholder xs={8} />
                                        <Placeholder xs={11} /> <Placeholder xs={8} />
                                    </Placeholder>
                                </Card.Body>
                                <Placeholder as={Card.Footer} animation="wave" className="border-0 bg-transparent px-0 mx-0 pt-0">
                                    <Placeholder xs={7} />
                                </Placeholder>
                            </Card>
                        </Col>
                    ))
                ) : (
                    feed.slice(0, 3).map((item, idx) => (
                        <Col key={idx}>
                            <Card className="h-100 gy-2 bg-transparent border-0">
                                <Card.Img  className="rounded-4" variant="top" src={item.image} title={item.title} alt={item.title} />
                                <Card.Body className="px-0 mx-0 pt-3 pb-2">
                                    <Card.Title className="fw-semibold">{item.title}</Card.Title>
                                    <Card.Text className="pt-2 pb-1 text-muted fw-semibold">
                                        <a title={item.title} href={item.link} rel="noopener noreferrer" target="_blank" className="stretched-link" style={{ cursor: "pointer" }}> </a>{item.description.slice(0, 100)}...
                                    </Card.Text>
                                </Card.Body>
                                <Card.Footer className="border-0 bg-transparent px-0 mx-0 pt-0">
                                    <small className="text-muted fw-semibold">{timeAgo(item.pubDate)}</small>
                                </Card.Footer>
                            </Card>
                        </Col>
                    ))
                )}
            </Row>
        </>
    );
};

export default Newsfeed;